
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.clientcabin-msn::v-deep {
    color: $black;
    background-color: $white;

    .option-title {
        font-size: 1.25rem;
        line-height: 1.8em;
        text-transform: uppercase;

        .option-plan {
            border: 2px solid grey;
            padding: 4px 8px;
            margin-left: 8px;
            font-weight: 600;
        }
    }

    ul {
        list-style: none;
        padding-left: 12px;

        li {
            line-height: 2.5em;
            display: flex;
            align-items: center;

            &:before {
                color: $white;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border-radius: 1rem;
                margin-right: 0.7rem;
                content: '✓';
                background: $clientcabin-apple;
                font-weight: 900;
            }
        }
    }

    .quote {
        font-style: italic;

        &:before {
            content: '\201C';
            font-size: 175%;
            line-height: 0;
            position: relative;
            top: 0.85rem;
            font-family: sans-serif;
        }

        &:after {
            content: '\201D';
            font-size: 175%;
            line-height: 0;
            position: relative;
            top: 0.85rem;
            font-family: sans-serif;
        }
    }

    .price {
        font-weight: 700;
        font-size: 67px;
        letter-spacing: -1px;
        text-align: center;
        padding: 25px 0;
        color: $black;
    }
}
